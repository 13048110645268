import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all'

const dev = process.env.NODE_ENV !== 'production';

const mm = gsap.matchMedia();

mm.add('(min-width: 768px)', () => {
    gsap.registerPlugin(ScrollTrigger);

    const timelines = [
        {

            '.dammy': {
                scale: 1.2, ease: 'none', transformOrigin: 'left'
            },
            '#section1>.right': {
                translateX: 0, ease: 'none', delay: '-0.4'
            },
            '#section1>.background': {
                filter: 'blur(5px)',
            },
            'empty': {
                duration: '+25%'
            },
        },
        {
            '#section2>.right': { height: '100vh', ease: 'none' },
            'empty': { duration: '+25%' },

        },
        {
            '#section3>.content': { scale: 1, ease: 'none' },
            'empty': { duration: '+25%' },
        },
        {
            'empty': { duration: '+25%' },
            'empty': { duration: '+25%' },
            'empty': { duration: '+25%' },
            '#section4>.bottom': { translateX: '-50%', ease: 'none' },
            'empty': { duration: '+25%' },
        },
        {
            '#section5>.left': { translateX: '0px', ease: 'none' },
            '#section5>.right>.bg': { scale: 1.2, ease: 'none', delay: '-0.4' }
        }
    ]

    timelines.forEach((scenes, i) => {
        const tweens = Object.entries(scenes);
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: `#section${i + 1}`,
                scrub: true,
                markers: dev,
                pin: true,
                snap: 1,
                scroller: '.wrapper',
                anticipatePin: 1,
            },
        })

        tweens.forEach(([element, animation], i) => {
            element = element === 'empty' ? {} : element;
            timeline.to(element, animation);
        });
    });

    ScrollTrigger.normalizeScroll({
        allowNestedScroll: true,
    });
})

/* https://gsap.com/community/forums/topic/29694-scrolltrigger-between-two-scenes-snap-to-the-nearest-scene/ */
